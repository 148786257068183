<template>
  <div v-bind="panelAttributes">
    <p-list v-bind="listAttributes" @on-click="togglePanel">
      <template #prepend> <slot name="prepend" /></template>
      <template #append> <p-icon v-bind="iconAttributes" /></template>
    </p-list>

    <transition name="p-expansion-panel-slide">
      <main v-if="isExpanded" class="p-expansion-panel__content">
        <slot />
      </main>
    </transition>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['on-action'])

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  prependIcon: {
    type: String,
    default: null,
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: null,
  },
})

const isExpanded = ref(props.expanded)

watch(
  () => props.expanded,
  (value) => {
    isExpanded.value = value
  },
  { deep: true },
)

const togglePanel = () => {
  isExpanded.value = !isExpanded.value
  emit('on-action', props.id)
}

const panelAttributes = computed((): { class: Record<string, boolean> } => {
  return {
    class: {
      'p-expansion-panel': true,
      'p-expansion-panel--wrap': props.wrap,
      'p-expansion-panel--expanded': isExpanded.value,
    },
  }
})

const listAttributes = computed((): { title: string; description: string } => {
  return {
    title: props.title,
    description: props.description,
  }
})

const iconAttributes = computed((): { icon: string } => {
  return {
    icon: `caret-${isExpanded.value ? 'up' : 'down'}`,
  }
})
</script>
