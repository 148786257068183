<template>
  <div>
    <p-expansion-panel
      v-for="panel in panels"
      :id="panel.id"
      :key="panel.id"
      :title="panel.title"
      :description="panel.description"
      :expanded="expandedPanel === panel.id"
      :wrap
      @on-action="handlePanelAction"
    >
      <template #prepend>
        <slot name="prepend" :panel="panel" />
      </template>
      <template #append>
        <slot name="append" :panel="panel" />
      </template>
      <template #default>
        <slot :panel="panel" />
      </template>
    </p-expansion-panel>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  panels: {
    type: Array as () => { id: string; title: string; description?: string }[],
    required: true,
  },
  initiallyExpandedPanelId: {
    type: String,
    default: null,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
})

const expandedPanel = ref<string | null>(props.initiallyExpandedPanelId || null)

const handlePanelAction = (panelId: string) => {
  expandedPanel.value = expandedPanel.value === panelId ? null : panelId
}
</script>
