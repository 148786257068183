<template>
  <footer class="footer">
    <div class="container">
      <div v-if="$device.isDesktop" class="row">
        <div class="col-md-3">
          <paribu-self />
          <social-medias />
        </div>
        <div class="col-md-3">
          <div class="mb-32">
            <link-items caption="paribu.discover" :links="discover" />
          </div>
          <link-items style="margin-top: 48px" caption="paribu.support.get" :links="support" />
        </div>
        <div class="col-md-3">
          <link-items :links="resources" caption="general.reviewResources" />
        </div>
        <div class="col-md-3">
          <div class="footer__qrcode--wrapper">
            <div class="footer__qrcode">
              <nuxt-img src="/img/qrcode.svg" width="190" height="190" />
            </div>
            <div class="footer__qrcode--text">
              <p
                class="f-utility-14 t-foreground-tertiary px-20"
                v-html="$t('general.downloadApp')"
              />
            </div>
            <div class="footer__store">
              <nuxt-img src="/img/app-store.svg" width="113" height="30" />
              <nuxt-img src="/img/google-play.svg" width="113" height="30" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-xs-12">
          <expansion-panel-group :panels="panels" initially-expanded-panel-id="panel1">
            <template #default="{ panel }">
              <div v-if="panel.id === 'panel1'">
                <div :title="panel.title">
                  <link-items :links="discover" />
                </div>
              </div>
              <div v-else-if="panel.id === 'panel2'">
                <link-items :links="support" />
              </div>
              <div v-else>
                <link-items :links="resources" />
              </div>
            </template>
          </expansion-panel-group>

          <p-link size="md" target="_blank" label="ParibuLog" href="https://www.paribu.com/blog/" />
          <social-medias />
          <p-divider class="mt-32" />
          <paribu-self />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import ParibuSelf from './ParibuSelf/index.vue'
import SocialMedias from './SocialMedias/index.vue'
import LinkItems from './LinkItems/index.vue'

const discover = [
  {
    name: 'paribu.about',
    link: 'https://www.paribu.com/destek/#/guide/1',
  },
  {
    name: 'paribu.openPositions',
    link: 'https://www.paribu.com/blog/kariyer',
  },
  {
    name: 'paribu.systemCheck',
    link: 'https://status.paribu.com/',
  },
  {
    name: 'general.policies',
    link: 'https://destek.paribu.com/hc/tr/sections/115000491029-G%C3%BCvenlik',
  },
]

const support = [
  {
    name: 'paribu.support.center',
    link: 'https://www.paribu.com/destek/#/',
  },
  {
    name: 'paribu.support.createRequest',
    link: 'https://www.paribu.com/destek/#/',
  },
]

const resources = [
  {
    name: 'account.security',
    link: 'https://www.paribu.com/destek/#/accountAccessAndSecurity',
  },
  {
    name: 'paribu.membership.info',
    link: 'https://www.paribu.com/destek/#/accountInfo',
  },
  {
    name: 'transaction.tl',
    link: 'https://www.paribu.com/destek/#/fiatTransactions',
  },
  {
    name: 'transaction.crypto',
    link: 'https://www.paribu.com/destek/#/cryptoTransactions',
  },
  {
    name: 'transaction.fbToken',
    link: 'https://www.paribu.com/blog/sozluk/fenerbahce-token-fb-nedir/',
  },
  {
    name: 'transaction.trade',
    link: 'https://www.paribu.com/destek/#/trade',
  },
  {
    name: 'paribu.membership.corporate',
    link: 'https://www.paribu.com/destek/#/corporate',
  },
  {
    name: 'general.feedbacks',
    link: 'https://www.paribu.com/destek/#/feedback',
  },
  {
    name: 'general.institutionRequests',
    link: 'https://www.paribu.com/destek/#/institutions',
  },
  {
    name: 'general.partnershipRequests',
    link: 'https://www.paribu.com/destek/#/partnership',
  },
]

const panels: { id: string; title: string }[] = [
  { id: 'panel1', title: 'Paribu’yu keşfet' },
  { id: 'panel2', title: 'Destek al' },
  { id: 'panel3', title: 'Kaynakları incele' },
]
</script>
